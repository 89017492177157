import { Modal, Button } from "react-bootstrap";
import AsideModal from "../../Common/AsideModal/AsideModal";
import { useEffect, useState } from "react";
import yesIcon from "../../icons/check.png"
import noIcon from "../../icons/cross.png"
import axios from "axios";
import DataTable from "../../Common/DataTable/DataTable";
import EditAccount from "./EditAccount";

const accountsHeader = [{ id: 110, name: "Buyer number", sortable: true, flex: 1, propName: "buyer", isActive: false }, {
    id: 113, name: "Auction", sortable: true, flex: 1, propName: "auction", isActive: false
}, {
    id: 153, name: "Comment", sortable: true, flex: 1, propName: "comment", isActive: false
}, { id: 154, name: "Actions", sortable: false, flex: 0.5, propName: "none", isActive: false }]

const Accounts = () => {
    const [notification, setNotification] = useState({
        show: false,
        title: "",
        body: "",
        button: ""
    });
    const [data, setData] = useState([]);
    const [title, setTitle] = useState("");
    const [active, setActive] = useState(false);
    const [showAsideModal, setShowAsideModal] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [accountData, setAccountData] = useState({
        buyer: "", auction: "", comment: ""
    })
    const [action, setAction] = useState("edit");

    useEffect(() => {
        axios('/api/accounts')
            .then((response) => {
                const accounts = response.data.data;
                setData([...accounts]);
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    const handlers = {
        editAccounts(account) {
            setShowAsideModal(true);
            setActive({ ...account });
            setAction("edit");
            setTitle("Edit Account");
            setAccountData({ buyer: account.buyer, auction: account.auction, comment: account.comment, password: account.password });
        },
        addAccount() {
            setShowAsideModal(true);
            setTitle("Add account");
            setAction("add");
            setAccountData({ buyer: "", auction: "", comment: "", password: "" });
        },
        deleteAccount(account) {
            setActive({ ...account });
            setShowDeleteAccountModal(true)
        },
        edit() {
            axios.put(`/api/accounts/${active.id}`, accountData)
                .then(() => {
                    setShowAsideModal(false);
                    const ac = data.map((a) => {
                        if (a.id === active.id) return { id: a.id, buyer: accountData.buyer, auction: accountData.auction, comment: accountData.comment };
                        return a
                    })

                    setData([...ac]);
                })
                .catch((err) => {
                    setShowAsideModal(false);
                    setNotification({
                        show: true,
                        title: "Edit account error",
                        body: err.response.data.message,
                        button: "Close"
                    })
                })
        },
        add() {
            axios.post(`/api/accounts`, accountData)
                .then((response) => {
                    const ac = response.data.data;
                    setData((prev) => ([ac, ...prev]));
                    setShowAsideModal(false)
                })
                .catch((err) => {
                    setShowAsideModal(false);
                    setNotification({
                        show: true,
                        title: "Add account error",
                        body: err.response.data.message,
                        button: "Close"
                    })
                })
        },
        delete() {
            axios.delete(`/api/accounts/${active.id}`)
                .then(() => {
                    const edit = data.filter((a) => a.id !== active.id);

                    setData([...edit]);
                    setShowDeleteAccountModal(false)
                })
                .catch((err) => {
                    setShowDeleteAccountModal(false);
                    setNotification({
                        show: true,
                        title: "Detele account error",
                        body: err.response.data.message,
                        button: "Close"
                    })
                })
        }
    }

    return (
        <div className="users-table">
            <div className="main-actions"><button className="action-main-buttons" onClick={handlers.addAccount}>Add new</button></div>
            <Modal show={showDeleteAccountModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <div className="modal-title h4">Delete admin</div>
                </Modal.Header>
                <Modal.Body>
                    <p> Are you sure you want to proceed?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={() => {
                        setShowDeleteAccountModal(false);
                    }}> Cancel
                    </button>
                    <Button variant="info" onClick={handlers.delete}>Delete</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={notification.show} size="md" aria-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))} centered>
                <Modal.Header>
                    <div className="modal-title h4">{notification.title}</div>
                </Modal.Header>
                <Modal.Body>
                    <p> {notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>
            {
                showAsideModal && <AsideModal visible={showAsideModal} size={"400px"} title={title} setVisible={setShowAsideModal}><EditAccount data={accountData} action={action} onSave={handlers.edit} onAdd={handlers.add} dataSetter={setAccountData} /></AsideModal>
            }
            <DataTable data={data} showSort={true} header={accountsHeader} editAction={handlers.editAccounts} deleteAction={handlers.deleteAccount} yesIcon={yesIcon} noIcon={noIcon} actions={true} dataSetter={setData} hiddenCol={["id", "password"]} />
        </div>
    )
}

export default Accounts