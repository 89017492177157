import axios from "axios"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";

const EditUser = ({ data, dataSetter, action, onSave, onAdd, admins }) => {
    const role = useSelector((state) => state.user.role);
    const [error, setError] = useState({
        email: false,
        username: false,
        city: false,
        copartAccount: false,
        country: false,
        name: false,
        phone: false,
        status: false,
        surname: false,
        password: false,
        carsHistory: false
    })
    const [account, setAccount] = useState({ copart: [], iaai: [] });
    const [accountBackup, setAccountBackup] = useState({});

    useEffect(() => {
        if (role === "sadmin") {
            axios('/api/accounts')
                .then((response) => {
                    let accounts = response.data.accounts;
                    setAccount({ copart: [...accounts.copart], iaai: [...accounts.iaai] });
                    setAccountBackup({ copart: [...accounts.copart], iaai: [...accounts.iaai] });
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            axios('/api/admin')
                .then((response) => {
                    let admin = response.data.data;
                    setAccount({ copart: [admin.copartAccount], iaai: [admin.iaaiAccount] });
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [role]);

    const handlers = {
        changeAdmin(e){
            dataSetter((prev) => ({ ...prev, adminName: e.target.value }));
            const activeAdmin = admins.filter((a) => a.username === e.target.value);
            if(activeAdmin[0].role === "sadmin") {
                setAccount({...accountBackup})
            }else{
                const ac = {copart: [{buyer: activeAdmin[0].copartAccount}], iaai: typeof activeAdmin[0].iaaiAccount === "string" ? [{buyer: activeAdmin[0].iaaiAccount}] : []};
                setAccount({...ac});
            }
        }
    }

    return (
        <form className="edit-users-form-container" onSubmit={(e) => {
            e.preventDefault();
            if(action == "edit"){
                onSave()
            }else{
                onAdd()
            }
        }}>
            <div className="form-group">
                <label className='form-label'>Email</label>
                <input required type="email" className={`form-control ${error.email && 'form-control-error'}`} value={data.email} onChange={(e) => dataSetter((prev) => ({ ...prev, email: e.target.value }))} placeholder="Enter email" />
                {error.email && <span className="form-error">Email is required</span>}
            </div>
            <div className="form-group">
                <label className='form-label'>Username</label>
                <input required className={`form-control ${error.username && 'form-control-error'}`} type="text" onChange={(e) => dataSetter((prev) => ({ ...prev, username: e.target.value }))} value={data.username} placeholder="Enter username" />
                {error.username && <span className="form-error">Username is required</span>}
            </div>
            <div className="form-group">
                <label className='form-label'>Password</label>
                <input required type="password" className={`form-control ${error.password && 'form-control-error'}`} onChange={(e) => dataSetter((prev) => ({ ...prev, password: e.target.value }))} value={data.password} placeholder="Enter password" />
                {error.password && <span className="form-error">Password is required</span>}
            </div>
            {role === "sadmin" && <div className="form-group">
                <label className='form-label'>Admin name</label>
                <select required value={data.adminName} className="select" onChange={handlers.changeAdmin}>
                    <option >Admin name</option>
                    {
                        admins.map((a) => {
                            return <option key={a.id} value={a.username}>{a.username}</option>
                        })
                    }
                </select>
            </div>}
            <div className="form-group">
                <label className='form-label'>Copart account</label>
                <select value={data.copartAccount} className="select" onChange={(e) => dataSetter((prev) => ({ ...prev, copartAccount: e.target.value }))}>
                    <option value="" >Copart account</option>
                    {
                        account.copart.map((a) => {
                            return <option key={role === "sadmin" ? a.buyer : a} value={role === "sadmin" ? a.buyer : a}>{role === "sadmin" ? a.buyer : a}</option>
                        })
                    }
                </select>
            </div>
            <div className="form-group">
                <label className='form-label'>IAAI account</label>
                <select value={data.iaaiAccount} className="select" onChange={(e) => dataSetter((prev) => ({ ...prev, iaaiAccount: e.target.value }))}>
                    <option value="">IAAI account</option>
                    {
                        account.iaai.map((a) => {
                            return <option key={role === "sadmin" ? a.buyer : a} value={role === "sadmin" ? a.buyer : a}>{role === "sadmin" ? a.buyer : a}</option>
                        })
                    }
                </select>
            </div>
            <div className="form-group">
                <label className='form-label'>Country</label>
                <input required className={`form-control ${error.country && 'form-control-error'}`} onChange={(e) => dataSetter((prev) => ({ ...prev, country: e.target.value }))} value={data.country} type="text" placeholder="Enter country" />
                {error.country && <span className="form-error">Country is required</span>}
            </div>
            <div className="form-group">
                <label className='form-label'>City</label>
                <input className={`form-control ${error.city && 'form-control-error'}`} value={data.city} onChange={(e) => dataSetter((prev) => ({ ...prev, city: e.target.value }))} type="text" placeholder="Enter city" />
                {error.city && <span className="form-error">City is required</span>}
            </div>
            <div className="form-group">
                <label className='form-label'>Name</label>
                <input required className={`form-control ${error.name && 'form-control-error'}`} onChange={(e) => dataSetter((prev) => ({ ...prev, name: e.target.value }))} value={data.name} type="text" placeholder="Enter name" />
                {error.name && <span className="form-error">Name is required</span>}
            </div>
            <div className="form-group">
                <label className='form-label'>Surname</label>
                <input required className={`form-control ${error.surname && 'form-control-error'}`} onChange={(e) => dataSetter((prev) => ({ ...prev, surname: e.target.value }))} value={data.surname} type="text" placeholder="Enter surname" />
                {error.surname && <span className="form-error">Surname is required</span>}
            </div>
            <div className="form-group">
                <label className='form-label'>Phone</label>
                <input className={`form-control ${error.phone && 'form-control-error'}`} onChange={(e) => dataSetter((prev) => ({ ...prev, phone: e.target.value }))} value={data.phone} type="text" placeholder="Enter phone" />
                {error.phone && <span className="form-error">Phone is required</span>}
            </div>
            <div className="form-group">
                <label className='form-label'>Bid limit</label>
                <input className={`form-control`} onChange={(e) => dataSetter((prev) => ({ ...prev, bidLimit: e.target.value }))} value={data.bidLimit} type="text" placeholder="Enter bid limit" />
            </div>
            <div className="form-group">
                <label className='form-label'>Lot history limit</label>
                <input className={`form-control`} onChange={(e) => dataSetter((prev) => ({ ...prev, lotHistoryLimit: e.target.value }))} value={data.lotHistoryLimit} type="text" placeholder="Enter lot history limit" />
            </div>
            <div className="checkboxes">
                <div className="checkbox-box">
                    <label className="checkbox-label" htmlFor="copart-status">Copart</label>
                    <input type="checkbox" id="copart-status" onChange={(e) => dataSetter((prev) => ({ ...prev, copartStatus: e.target.checked }))} defaultChecked={[data].some(i => i.copartStatus)} />
                </div>
                <div className="checkbox-box" style={{ marginLeft: "20px" }}>
                    <label className="checkbox-label" htmlFor="iaai-status">IAAI</label>
                    <input type="checkbox" id="iaai-status" onChange={(e) => dataSetter((prev) => ({ ...prev, iaaiStatus: e.target.checked }))} defaultChecked={[data].some(i => i.iaaiStatus)} />
                </div>
                <div className="checkbox-box" style={{ marginLeft: "20px" }}>
                    <label className="checkbox-label" htmlFor="pre-bid">Prebid</label>
                    <input type="checkbox" id="pre-bid" onChange={(e) => dataSetter((prev) => ({ ...prev, prebidStatus: e.target.checked }))} defaultChecked={[data].some(i => i.prebidStatus)} />
                </div>
                <div className="checkbox-box" style={{ marginLeft: "20px" }}>
                    <label className="checkbox-label" htmlFor="live-bid" >Live bid</label>
                    <input type="checkbox" id="live-bid" onChange={(e) => dataSetter((prev) => ({ ...prev, liveBidStatus: e.target.checked }))} defaultChecked={[data].some(i => i.liveBidStatus)} />
                </div>
                <div className="checkbox-box" style={{ marginLeft: "20px" }}>
                    <label className="checkbox-label" htmlFor="live-pre-bid" >Pre bid in live</label>
                    <input type="checkbox" id="live-pre-bid" onChange={(e) => dataSetter((prev) => ({ ...prev, livePreBid: e.target.checked }))} defaultChecked={[data].some(i => i.livePreBid)} />
                </div>
                <div className="checkbox-box" style={{ marginLeft: "20px" }}>
                    <label className="checkbox-label" htmlFor="cars-history" >Cars history</label>
                    <input type="checkbox" id="cars-history" onChange={(e) => dataSetter((prev) => ({ ...prev, carsHistory: e.target.checked }))} defaultChecked={[data].some(i => i.carsHistory)} />
                </div>
            </div>
            <div className="submit-button-container">
                {action === "edit" && <button type="submit" className="save-user-button">Save</button>}
                {action === "add" && <button type="submit" className="save-user-button">Add new</button>}
            </div>
        </form>
    )
}

export default EditUser