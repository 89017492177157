import axios from "axios"
import { useEffect, useState } from "react"
import DataTable from "../../Common/DataTable/DataTable"
import usersHeader from "../../json/usersHeader"
import yesIcon from "../../icons/check.png"
import noIcon from "../../icons/cross.png"
import { Button, Modal } from "react-bootstrap"
import { useSelector } from "react-redux"

import "./Users.css"
import AsideModal from "../../Common/AsideModal/AsideModal"
import EditUser from "./EditUser"

const Users = () => {
    const [notification, setNotification] = useState({
        show: false,
        title: "",
        body: "",
        button: ""
    });
    const role = useSelector((state) => state.user.role);
    const [showAddUsers, setShowAddUsers] = useState(false)
    const [data, setData] = useState([]);
    const [backup, setBackup] = useState([])
    const [active, setActive] = useState({});
    const [keyWord, setKeyword] = useState('');
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [actionType, setActiontype] = useState("edit");
    const [admins, setAdmins] = useState([]);
    const [userForm, setUserForm] = useState({
        username: "", name: "", surname: "", country: "", city: "", email: "",
        phone: "", password: "", linkedToAdmin: "", adminName: "", copartStatus: "",
        copartAccount: "", iaaiStatus: "", iaaiAccount: "", prebidStatus: "",
        liveBidStatus: "", bidLimit: "", purchaseLimit: "", livePreBid: false, carsHistory: false, lotHistoryLimit: 0
    })

    useEffect(() => {
        axios.get('/api/users')
            .then((response) => {
                const data = response.data.data;
                setData([...data]);
                setBackup([...data]);
            });
        axios.get('/api/admins')
            .then((response) => {
                const admin = response.data.data;
                setAdmins([...admin]);
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    const handlers = {
        editUser(user) {
            console.log(user)
            setShowAddUsers(true);
            setUserForm({
                username: user.username, name: user.name, surname: user.surname, country: user.country, city: user.city, email: user.email,
                phone: user.phone, linkedToAdmin: user.linkedToAdmin, adminName: user.adminName, copartStatus: user.copartStatus,
                copartAccount: user.copartAccount, iaaiStatus: user.iaaiStatus, iaaiAccount: user.iaaiAccount, prebidStatus: user.prebidStatus,
                liveBidStatus: user.liveBidStatus, bidLimit: user.bidLimit, purchaseLimit: user.purchaseLimit, livePreBid: user.livePreBid, password: user.password,
                lotHistoryLimit: user.lotHistoryLimit
            });
            setActiontype('edit')
            setActive({ ...user });
        },
        deleteUser(user) {
            setShowDeleteUserModal(true);
            setActive({ ...user });
        },
        addUser() {
            setShowAddUsers(true);
            setActiontype('add');
            setUserForm({
                username: "", name: "", surname: "", country: "", city: "", email: "",
                phone: "", password: "", linkedToAdmin: true, adminName: "", copartStatus: false,
                copartAccount: "", iaaiStatus: false, iaaiAccount: "", prebidStatus: false,
                liveBidStatus: false, bidLimit: "0", purchaseLimit: "0", livePreBid: false, lotHistoryLimit: "0",carsHistory: false
            })
        },
        saveUserData() {
            axios.put(`/api/user/${active.id}`, userForm)
                .then(() => {
                    setShowAddUsers(false);
                    const edits = data.map((d) => {
                        if (d.id === active.id) {
                            return { ...d, ...userForm }
                        } else {
                            return { ...d }
                        }
                    })
                    setData([...edits])
                })
                .catch((err) => {
                    setShowAddUsers(false);
                    setNotification({
                        show: true,
                        title: "Edit user error",
                        body: err.response.data.message,
                        button: "Close"
                    })
                })
        },
        addUserData() {
            axios.post(`/api/users`, userForm)
                .then((response) => {
                    setShowAddUsers(false);
                    setData((prev) => ([response.data.data, ...prev]))
                })
                .catch((err) => {
                    setShowAddUsers(false);
                    setNotification({
                        show: true,
                        title: "Add user error",
                        body: err.response.data.message,
                        button: "Close"
                    })
                })
        },
        delete() {
            axios.delete(`/api/user/${active.id}`)
                .then((response) => {
                    const status = response.data.description;
                    if (status === "success") {
                        const renew = data.filter((u) => u.id !== active.id)
                        setData([...renew]);
                        setShowDeleteUserModal(false)
                    }
                })
                .catch((err) => {
                    setShowDeleteUserModal(false);
                    setNotification({
                        show: true,
                        title: "Edit user error",
                        body: err.response.data.message,
                        button: "Close"
                    })
                })
        }
    }

    const filters = {
        status(e) {
            const value = e.target.value;
            let result = backup.filter((u) => u.status === value);
            if (value === "") result = [...backup];
            setData([...result])
        },
        copart(e) {
            const value = e.target.value;
            let result = backup.filter((u) => {
                if (value === "enabled") return u.copartStatus === true;
                if (value === "disabled") return u.copartStatus === false;
                if (value === "") return u;
            })

            setData([...result])
        },
        iaai(e) {
            const value = e.target.value;
            let result = backup.filter((u) => {
                if (value === "enabled") return u.iaaiStatus === true;
                if (value === "disabled") return u.iaaiStatus === false;
                if (value === "") return u;
            })

            setData([...result])
        },
        admin(e) {
            const value = e.target.value;
            let result = backup.filter((u) => u.adminName === value);
            if (value === "") result = [...backup];
            setData([...result])
        }
    }

    return (
        <div className="users-table">
            <Modal show={showDeleteUserModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <div className="modal-title h4">Delete user</div>
                </Modal.Header>
                <Modal.Body>
                    <p> Are you sure you want to proceed?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={() => {
                        setShowDeleteUserModal(false);
                    }}> Cancel
                    </button>
                    <Button variant="info" onClick={handlers.delete}>Delete</Button>
                </Modal.Footer>
            </Modal>
            <div className="filters-container">
                <input type="text" className="search-form" onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        setKeyword(e.target.value);
                    }
                }} placeholder="Enter keyword" />
                {role === "sadmin" && <select className="select-form-control" onChange={filters.status}>
                    <option value="">User status</option>
                    <option value="User managed">User managed</option>
                    <option value="Signed up">Signed up</option>
                </select>}
                <select className="select-form-control" onChange={filters.copart}>
                    <option value="">Copart</option>
                    <option value="enabled">Active</option>
                    <option value="disabled">Inactive</option>
                </select>
                <select className="select-form-control" onChange={filters.iaai}>
                    <option value="">IAAI</option>
                    <option value="enabled">Active</option>
                    <option value="disabled">Inactive</option>
                </select>
                {role === "sadmin" && <select className="select-form-control" onChange={filters.admin}>
                    <option value="">Admin</option>
                    {
                        admins.map((a) => {
                            return <option key={a.id} value={a.username}>{a.username}</option>
                        })
                    }
                </select>}
            </div>
            <Modal show={notification.show} size="md" aria-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))} centered>
                <Modal.Header>
                    <div className="modal-title h4">{notification.title}</div>
                </Modal.Header>
                <Modal.Body>
                    <p> {notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>
            <div className="main-actions"><button onClick={handlers.addUser} className="action-main-buttons">Add new</button></div>
            {showAddUsers && <AsideModal visible={showAddUsers} size={"400px"} title={actionType === "edit" ? "Edit user" : "Add new user"} setVisible={setShowAddUsers}><EditUser admins={admins} onAdd={handlers.addUserData} action={actionType} data={userForm} onSave={handlers.saveUserData} dataSetter={setUserForm} /></AsideModal>}
            <DataTable data={data} showSort={true} header={usersHeader} searchKeyword={keyWord} editAction={handlers.editUser} deleteAction={handlers.deleteUser} yesIcon={yesIcon} noIcon={noIcon} actions={true} dataSetter={setData} hiddenCol={["id", "bidLimit", "password", "purchaseLimit", "lastPurchaseDate", "prebidStatus", "liveBidStatus", "copartAccount", "carsPurchased", "email", "lotHistoryLimit", "iaaiAccount", "livePreBid", "linkedToAdmin", "name", "phone", "surname"]} />
        </div>
    )
}

export default Users